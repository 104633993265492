import React, { useState, useRef } from "react";
import {
  Box,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Link,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import SecondPageBanner from "./banner";
import Deals from "./deals";
import TrendingProducts from "./trending-products";
import AllProduct from "./all-product";
import Footer from "./footer";
import imageurl from "services/images";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const HeaderSecond = ({ loading }) => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const searchBarRef = useRef(null);

  const handleIconClick = () => {
    setIsExpanded(!isExpanded);
    if (searchBarRef.current) {
      searchBarRef.current.focus();
    }
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && searchInput.trim()) {
      navigate(`/search-gifts?query=${searchInput}`);
    }
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Box sx={{ background: "#fff", position: "relative" }}>
      <Box sx={{ background: "#fff", padding: "10px 20px" }}>
        <Grid container alignItems="center">
          <Grid item xs={4} container alignItems="center">
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton onClick={toggleMenu}>
                <MenuIcon fontSize="large" />
              </IconButton>
            </Box>

            <Box sx={{ display: { xs: "none", md: "block" }, marginLeft: "10px" }}>
              <Link
                href="https://gimmie.ai/gimme/term&condition"
                target="_blank"
                underline="none"
                sx={{ marginRight: "15px", color: ` rgba(55, 92, 101, 1)`, fontWeight: "bold" }}
              >
                Terms & Conditions
              </Link>
              <Link href="https://gimmie.ai/gimme/privacy&policy" target="_blank" underline="none" sx={{ color: ` rgba(55, 92, 101, 1)`, fontWeight: "bold" }}>
                Privacy Policy
              </Link>
            </Box>
          </Grid>

          <Grid item xs={4} container justifyContent="center">
            <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
              <img
                onClick={handleLogoClick}
                src={imageurl.secondLogo}
                alt="Login-logo"
                style={{
                  padding: 10,
                  maxWidth: "90%",
                  width: "100%",
                  mixBlendMode: "darken",
                }}
              />
              <Typography
                sx={{
                  color: "rgba(55, 92, 101, 1)",
                  fontWeight: 400,
                }}
                variant="h2"
              >
                Gimmie
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} container alignItems="center" justifyContent={{ md: "flex-end", xs: "center" }}>
            {!isExpanded && (
              <IconButton onClick={handleIconClick} disableRipple={true}>
                <SearchIcon fontSize="large" />
              </IconButton>
            )}

            <Box
              sx={{
                display: isExpanded ? "block" : "none",
                opacity: isExpanded ? 1 : 0,
                transition: "width 0.5s ease-in-out, opacity 0.3s ease-in-out",
                overflow: "hidden",
                ml: 2,
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Search for Gifts"
                value={searchInput}
                onChange={handleSearchChange}
                onKeyPress={handleKeyPress}
                inputRef={searchBarRef}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "25px",
                  },
                  width: isExpanded
                    ? { xs: "180px", md: "250px", lg: "350px" }
                    : "0px",
                }}
                InputProps={{
                  endAdornment: loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : null,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Drawer anchor="left" open={menuOpen} onClose={toggleMenu}>
        <Box
          sx={{
            padding: "20px",
            background: "linear-gradient(135deg, #f3f4f7, #ffffff)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <IconButton
            onClick={toggleMenu}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "#333",
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>

          <img
            onClick={handleLogoClick}
            src={imageurl.secondLogo}
            alt="Login-logo"
            style={{
              padding: 10,
              maxWidth: "70%",
              mixBlendMode: "darken",
              marginBottom: "20px",
              cursor: "pointer",
              borderRadius: "10px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.3s ease-in-out",
            }}
            onMouseOver={(e) => e.target.style.transform = "scale(1.05)"}
            onMouseOut={(e) => e.target.style.transform = "scale(1)"}
          />
          <List sx={{ width: "100%" }}>
            <ListItem button onClick={() => window.open("https://gimmie.ai/gimme/term&condition", "_blank")} sx={{ padding: "10px 20px", borderRadius: "8px", transition: "background-color 0.3s" }}>
              <ListItemText primary="Terms & Conditions" sx={{ fontWeight: 600, color: "#333" }} />
            </ListItem>
            <ListItem button onClick={() => window.open("https://gimmie.ai/gimme/privacy&policy", "_blank")} sx={{ padding: "10px 20px", borderRadius: "8px", transition: "background-color 0.3s" }}>
              <ListItemText primary="Privacy Policy" sx={{ fontWeight: 600, color: "#333" }} />
            </ListItem>
          </List>
        </Box>
      </Drawer>


      {/* Other Components */}
      <SecondPageBanner />
      <Deals />
      <TrendingProducts />
      <AllProduct />
      <Footer />
    </Box>
  );
};

export default HeaderSecond;
